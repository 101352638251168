import React from 'react';
import { useRouter } from 'next/router';
import { useUser, useNotify } from '../hooks';
import { Loading } from '../components/common';

interface AuthProps {
  permissionKey?: string;
  permissionOption?: string;
}

function Auth({ permissionKey, permissionOption }: AuthProps) {
  const [user] = useUser();
  const notify = useNotify();
  const router = useRouter();
  const [hasAccess, setHasAccess] = React.useState(false);
  const [triggerHasAccess, setTriggerHasAccess] = React.useState(false);
  const [timer, setTimer] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setTimer(true);
    }, 5);
  }, []);

  React.useEffect(() => {
    if (timer) {
      setHasAccess(
        user?.permissions?.filter(
          (permission) =>
            (permission?.key === permissionKey &&
              permission?.option === permissionOption) ||
            permission?.key === 'superadmin' ||
            permission?.key === 'enterpriseUser'
        )?.length > 0
      );
      // setHasAccess(
      //   user?.permissions?.filter(
      //     (permission) =>
      //       (permission?.key === permissionKey &&
      //         permission?.option === permissionOption) ||
      //       permission?.key === 'superadmin'
      //   )?.length > 0
      // );
      // setHasAccess(
      //   user?.permissions?.filter(
      //     (permission) => permission?.key === 'enterpriseUser'
      //   )?.length > 0
      // );

      setTriggerHasAccess(true);
    }
  }, [timer]);

  React.useEffect(() => {
    if (!hasAccess && timer) {
      notify('Acceso denegado', 'warning');
      router.push('/');
    }
  }, [hasAccess, triggerHasAccess]);
  return <div />;
}

export default Auth;
