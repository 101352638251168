/* eslint-disable no-nested-ternary */
import React from 'react';
import { useQuery } from '@apollo/client';
import { motion } from 'framer-motion';
import { Layout } from '../../components/layout';
import { useUser } from '../../hooks';
import { Auth, KEYS, OPTIONS } from '../../lib';
import HomeIndex from '../../components/home/Home';

function Home() {
  const [user] = useUser();
  const animation = {
    initial: { opacity: 0, scale: 0.975 },
    animate: { opacity: 1, scale: 1 },
    transition: { duration: 0.6 },
  };
  return (
    <>
      <Auth permissionKey={KEYS.ADMIN} permissionOption={OPTIONS.AUTH} />
      <Layout
        title=""
        routesHistory={{
          text: 'Roles y privilegios',
          href: `/app/Home`,
          key: 'Home',
        }}
      >
        <motion.div className="bg-background-200 h-full" {...animation}>
          <div className="w-full max-w-7xl gap-6 -mt-12 flex flex-col">
            <div className="flex flex-row gap-1 items-center">
              <img
                alt=""
                src="widu-icon.png"
                className=" h-7 w-7 flex items-end"
              />
              <h1 className="text-neutral-700">{`¡Hola, ${user?.firstName}!`}</h1>
            </div>
            <p>¡Bienvenido a tu plataforma administrativa!</p>
            <div className="w-full md:h-64 h-32 shadow-lg rounded-lg flex flex-col items-center justify-center bg-gradient-to-r from-[#244CA5] to-primary-300 relative">
              <img
                alt=""
                src="white-logo copy.png"
                className=" h-6 md:h-14 flex w-1/3 bottom-0"
              />
              {/* <h1>Bienvenido a la plataforma administrativa</h1> */}
              <img
                alt=""
                src="waves.png"
                className="absolute h-24 flex items-end w-full bottom-0"
              />
            </div>
            <div className="mt-10">
              <HomeIndex permissions={user?.permissions} />
            </div>
          </div>
        </motion.div>
      </Layout>
    </>
  );
}

export default Home;
