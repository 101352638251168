export const KEYS = {
  CATEGORY: 'category',
  CLIENT: 'client',
  COUNTRY: 'country',
  CURRENCY: 'currency',
  DOCUMENT: 'document',
  FIRM: 'firm',
  LAWYER: 'lawyer',
  ORDER: 'order',
  PAYMENT_METHOD: 'paymentMethod',
  QUESTIONNAIRE: 'questionnaire',
  TAX: 'tax',
  USER: 'user',
  PROMOTION: 'promotion',
  GUIDE: 'guide',
  PAYMENT: 'payment',
  ADMIN: 'admin',
  ENTERPRISE: 'enterprise',
  ENTERPRISE_USER: 'enterpriseUser',
} as const;

export const OPTIONS = {
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
  AUTH: 'auth',
} as const;

export const getRoute = (key) => {
  switch (key) {
    case 'user':
      return 'users';
    case 'lawyer':
      return 'lawyers';
    case 'firm':
      return 'firms';
    case 'category':
      return 'categories';
    case 'client':
      return 'clients';
    case 'country':
      return 'countries';
    case 'currency':
      return 'currencies';
    case 'document':
      return 'documents';
    case 'order':
      return 'orders';
    case 'paymentMethod':
      return 'payment-methods';
    case 'questionnaire':
      return 'questionnaires';
    case 'tax':
      return 'taxes';
    case 'promotion':
      return 'promotions';
    case 'guide':
      return 'guides';
    case 'payment':
      return 'payments';
    case 'enterpriseUser':
      return 'enterpriseUser';

    default:
      break;
  }
};

export const routesArray = [
  { key: 'Dashboard', route: 'dashboard' },
  { key: 'Roles y privilegios', route: 'users' },
  { key: 'Abogados', route: 'lawyers' },
  { key: 'Despachos', route: 'firms' },
  { key: 'Categorías', route: 'categories' },
  { key: 'Clientes', route: 'clients' },
  { key: 'Países', route: 'countries' },
  { key: 'Monedas', route: 'currencies' },
  { key: 'Documentos', route: 'documents' },
  { key: 'Órdenes', route: 'orders' },
  { key: 'Métodos de pago', route: 'payment-methods' },
  { key: 'Cuestionarios', route: 'questionnaires' },
  { key: 'Impuestos', route: 'taxes' },
  { key: 'Promociones', route: 'promotions' },
  { key: 'Guías', route: 'guides' },
  { key: 'Pagos', route: 'payments' },
];
