/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Link from 'next/link';
import getIcon from './getRouteIcon';
import { IRoute } from '../routes';
import { useUser } from '../hooks';

interface BreadcrumbProps {
  actualRoute?: IRoute;
}

function Breadcrumb({ actualRoute }: BreadcrumbProps) {
  return (
    <>
      {/* Breadcrum */}
      <div className="flex gap-2 items-center justify-start">
        <Link href={actualRoute?.href}>
          {getIcon(
            actualRoute?.key ? actualRoute?.key : '',
            'text-neutral-400 fill-neutral-400 cursor-pointer'
          ) ?? ''}
        </Link>
        {actualRoute?.breadcrumb?.map((bc, index) =>
          index !== actualRoute.breadcrumb.length - 1 ? (
            <div className="flex gap-2" key={bc.name}>
              <p className=" text-neutral-400 cursor-pointer ">/</p>
              <Link href={bc?.href}>
                <p className=" text-neutral-400 cursor-pointer ">{bc?.name}</p>
              </Link>
            </div>
          ) : (
            <div className="flex gap-2" key={bc.name}>
              <p className=" text-neutral-400 cursor-pointer ">/</p>
              <Link href={bc?.href}>
                <p className=" text-black cursor-pointer font-medium ">
                  {bc?.name}
                </p>
              </Link>
            </div>
          )
        )}
      </div>
    </>
  );
}

export default Breadcrumb;
