import React from 'react';
import { Navbar, Footer, Sidebar } from '../common';
import Breadcrumb from '../../lib/breadcrumb';
import { IRoute } from '../../routes';

interface LayoutProps {
  children: React.ReactChild | Array<React.ReactChild>;
  title: string;
  routesHistory?: IRoute;
}

function Layout({ children, title = '', routesHistory }: LayoutProps) {
  const [isShown, setIsShown] = React.useState(false);
  const [isOpen, setOpen] = React.useState(false);
  return (
    <>
      <Sidebar isShown={isShown} setIsShown={setIsShown} setOpen={setOpen} />
      <section
        className={`relative bg-gray-100 ${isShown ? 'lg:ml-64' : 'lg:ml-12'}`}
      >
        <Navbar />
        <div className="relative pt-16 z-0">
          <div className="px-4 lg:py-6 lg:px-14 mx-auto w-full flex flex-col gap-4">
            {/* Breadcrumb */}
            <Breadcrumb actualRoute={routesHistory} />
            {/* Section name */}
            <h2 className="text-xl text-primary-500 font-semibold">{title}</h2>
          </div>
        </div>
        <div className="px-4 lg:py-3 lg:px-14 mx-auto w-full z-10 min-h-screen mt-4 lg:mt-0">
          {children}
        </div>
        <div className="px-4 lg:py-6 lg:px-14 mx-auto w-full z-10">
          <Footer />
        </div>
      </section>
    </>
  );
}

export default Layout;
